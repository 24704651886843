/* eslint-disable react/jsx-indent */
/* eslint-disable linebreak-style */
import React, {
  useEffect, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router-dom';
import {
  Grid, Tabs, Tab, Paper,
} from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CustomerDetails from '../../components/CustomerDetails';
import CustomButton from '../../components/CustomButton';
import { CustomerVisit } from '../../types/Visit';
import { Customer } from '../../components/CustomerDetails/CustomerDetails';
import CustomerVisits from '../../components/CustomerVisits/CustomerVisits';
import Spinner from '../../components/CustomSpinner';
import useStyles from '../../components/CustomerDetails/styles';
import {
  GET_CUSTOMER, GET_VISITS, UPDATE_CUSTOMER, GET_SURVEY_QUESTIONS,
} from '../../graphql/queries/CustomerDetails';
import {
  transformGraphQlErrorMessage,
} from '../../utils/helpers';
import useStaticCatalogs from '../../hooks/useStaticCatalogs';
import { SurveyResult, SurveyQuestions } from '../../types/CustomerSurveys';
import SurveyTab from '../../components/SurveyTab/SurveyTab';
import { BaseProperty as Property } from '../../types/Property';

enum TabIndex {
  FirstTab,
  SecondTab
}

type Props = {
} & RouteComponentProps & { match: { params: { customer_id: string } } };

const CustomerDetailsPage: React.FC<Props> = ({ history, match, location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [customer, setCustomer] = useState<Customer>(new Customer());
  const { sources, statuses, types } = useStaticCatalogs();
  const [visits, setVisits] = useState<Array<CustomerVisit>>([]);
  const [surveyResults, setSurveyResults] = useState<SurveyResult[]>([]);
  const [surveyQuestions, setServeyQuestions] = useState<Array<SurveyQuestions>>([]);
  const customerId = parseInt(match.params.customer_id, 10);
  const [tabIndex, setTabIndex] = useState<TabIndex>(0);
  const [formType, setType] = useState<'add' | 'edit'>((
    !match.params.customer_id || match.params.customer_id.toString() === 'add')
    ? 'add' : 'edit');
  const [property, setProperty] = useState<Property>({
    property_id: 0,
    address_1: '',
    builder: '',
    subdivision: '',
    hub: {
      serial_number: '',
      hub_type: '',
    },
    emergency_contact: {
      username: '',
      firstname: '',
      lastname: '',
      phone: ''
    },
    timezone: { value: '', catalog_id: 0 },
    lock_serial_number: '',
    smart_lock_serial_number: '',
    house_num: '',
    url_1: '',
    rental_application_url: '',
    grouping: '',
    adjustableTourHours: 0,
  });

  const {
    loading, data, refetch, error,
  } = useQuery <{
    customer: Customer,
    surveyAnswers: SurveyResult[]
  }>(
    GET_CUSTOMER,
    {
      variables: { customer_id: customerId },
      skip: !customerId,
      fetchPolicy: 'network-only',
    },
  );
  const { data: surveyQuestionsData } = useQuery<{
    surveyQuestions: SurveyQuestions[]
  }>(
    GET_SURVEY_QUESTIONS,
    {
      variables: { builder_id: property.builder_id },
      skip: !property.property_id,
      fetchPolicy: 'network-only',
    },
  );

  const { loading: visitLoading, data: visitData, refetch: visitRefetch } = useQuery<{
    visits: CustomerVisit[]
  }>(
    GET_VISITS,
    {
      variables: { customer_id: customerId },
      skip: !customerId,
      fetchPolicy: 'network-only',
    },
  );
  const [updateCustomer] = useMutation<{ updateCustomer: Customer }>(
    UPDATE_CUSTOMER,
  );

  const handleChooseProperty = (choosenProperty: Property) => {
    setProperty(choosenProperty);
  };

  const handleCustomerSave = (
    formCustomer: Customer,
    uCustomer: any,
  ) => {
    const { emergency_contacts: u_emergency_contacts, sales_agent: u_sales_agent, ...uCustomerNew } = uCustomer;
    const { emergency_contacts, sales_agent, ...formCustomerNew } = formCustomer;

    return updateCustomer({
    variables: {
      customer: {
        ...formCustomerNew,
        ...uCustomerNew,
      },
    },
  }).then(result => {
    const id = result?.data?.updateCustomer?.customer_id;
    if (id && formType === 'add') {
      setType('edit');
    }

    refetch({ customer_id: id }).then(res => {
      setCustomer(res.data.customer);
    })
      .then(() => {
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(res => {
        enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
      });

    visitRefetch({
      customer_id: id,
    }).then(res => {
      setVisits(res.data.visits);
    });
  })
    .catch(res => {
      enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
    });
  }

  useEffect(() => {
    if (data) {
      setCustomer(data.customer);
    }
    if (visitData) {
      setVisits(visitData.visits);
    }
    if (surveyQuestionsData) {
      setServeyQuestions(surveyQuestionsData.surveyQuestions);
    }
  }, [loading, data, visitData, surveyQuestionsData]);

  useEffect(() => {
    if (data && data.surveyAnswers) {
      setSurveyResults(data.surveyAnswers);
    }
  }, [data]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  if (loading || visitLoading) {
    return (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    enqueueSnackbar(transformGraphQlErrorMessage(error.message), { variant: 'error' });
  }

  const hasADCHub = property?.hub?.hub_type === 'ADC';
  const nnBackupCode = property?.propertyCommunity?.communities?.nternow_hub_backup_code;
  const tempBackupCode = property?.temp_backup_code;
  const communityBackupCode = property?.propertyCommunity?.communities?.community_backup_code;
  const pointPerson = property?.propertyCommunity?.communities?.emergency_contacts;
  const pointPersonNumber = property?.propertyCommunity?.communities?.phoneNumber;
  const tradeCode = property?.propertyCommunity?.communities?.trade_code;
  const salesCode = property?.propertyCommunity?.communities?.sales_code;
  const sales_agent = property?.propertyCommunity?.communities?.sales_agent;
  const propertyEmergencyContact = property?.emergency_contact;
  const shouldDisplayTabs = visits.length >= 1 || surveyResults.length >= 1;
  const shouldDisplayNotes = property?.grouping || property?.alarm;
  const shouldDisplayContacts = propertyEmergencyContact || sales_agent;
  // eslint-disable-next-line max-len
  const shouldDisplayAutomations = !hasADCHub && (nnBackupCode || tempBackupCode || communityBackupCode || tradeCode || salesCode || pointPerson || pointPersonNumber);


  return (
    <>
      <Grid container justify="space-between" className={classes.container}>
        <Grid item>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
            GoBack
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} sm={(shouldDisplayTabs && 7) || (shouldDisplayAutomations && 10) || 12}>
          <CustomerDetails
            history={history}
            customerId={parseInt(match.params.customer_id, 10)}
            visitorTypes={types}
            verificationSources={sources}
            visitorStatuses={statuses}
            formType={formType}
            surveyQuestions={surveyQuestions}
            initialCustomer={customer}
            handleSave={handleCustomerSave}
            handleChooseProperty={handleChooseProperty}
          />
        </Grid>
        <Grid container item xs spacing={4}>
          <Grid item xs={Boolean(shouldDisplayNotes) || Boolean(shouldDisplayAutomations)}>
          {shouldDisplayNotes
            && (
            <Grid item container xs direction="column" style={{ marginBottom: '20px' }}>
                {property?.grouping
                && (
                 <Grid item>
                    <Paper>
                      <div className={classes.marginNote}>
                        <p className={classes.notesHeader}>Essential Notes:</p>
                        <p className={classes.notesMessage}>
                          {property.grouping}
                        </p>
                      </div>
                    </Paper>
                 </Grid>
    )
                }
                {property?.alarm
                      && (
                      <Grid item>
                        <Paper>
                          <div className={classes.marginNote}>
                            <p className={classes.notesHeader}>Alarm Notes:</p>
                            <p className={classes.alarmMessage}>
                              {property.alarm_company}
                            </p>
                            <p className={classes.alarmMessage}>
        Armed:
                              <span style={{ color: '#DB5C0E' }}>
                                {` ${property.armed_hours ?? ''}`}
                              </span>
                            </p>
                            <p className={classes.alarmMessage}>
                                  Disable:
                              <span style={{ color: '#DB5C0E' }}>
                                {` ${property.disarm_code ?? ''}`}
                              </span>
                            </p>
                            <p className={classes.alarmMessage}>
                                  Emergency:
                              <span style={{ fontWeight: 400 }}>
                                {` ${property.emergency_notes ?? ''}`}
                              </span>
                            </p>
                          </div>
                        </Paper>
                      </Grid>
    )}
            </Grid>
            )}

          {shouldDisplayAutomations && (
            <>
              <Grid item xs>
                <Paper>
                  {tempBackupCode && (
                    <div className={classes.marginNote}>
                      <p className={classes.notesHeader}>Temp Backup Code:</p>
                      <p className={classes.notesMessage}>
                        {tempBackupCode}
                      </p>
                    </div>
                  )}
                  {communityBackupCode && (
                    <div className={classes.marginNote}>
                      <p className={classes.notesHeader}>Community Backup Code:</p>
                      <p className={classes.notesMessage}>
                        {communityBackupCode}
                      </p>
                    </div>
                  )}
                  {tradeCode && (
                    <div className={classes.marginNote}>
                      <p className={classes.notesHeader}>Trade Code:</p>
                      <p className={classes.notesMessage}>
                        {tradeCode}
                      </p>
                    </div>
                  )}
                  {salesCode && (
                    <div className={classes.marginNote}>
                      <p className={classes.notesHeader}>Sales Code:</p>
                      <p className={classes.notesMessage}>
                        {salesCode}
                      </p>
                    </div>
                  )}
                </Paper>
              </Grid>
              <Grid item xs className={classes.newBox}>
                  <Paper>
                    {pointPerson && pointPersonNumber && (
                      <div className={classes.newMarginNote}>
                        <p className={classes.newNotesHeader}>Point Person for issues</p>
                        <div className={classes.pointPerson}>
                          <p className={classes.notesMessage}>{pointPerson}</p>
                          <p className={classes.notesMessage}>{pointPersonNumber}</p>
                        </div>
                      </div>
                    )}

                  </Paper>
              </Grid>
            </>
          )}
          {shouldDisplayContacts 
            && (
              <Grid container xs direction="column" style={{ marginBottom: '20px' }}>
                <div className={classes.marginContacts}>
                  {propertyEmergencyContact && (
                    <>
                      <p className={classes.contactHeader}>Contact for issues:</p>
                      <p className={classes.contactName}>
                        {propertyEmergencyContact.firstname} {propertyEmergencyContact.lastname} 
                      </p>
                      <p className={classes.contactPhone}>
                        {propertyEmergencyContact.phone} 
                      </p>
                    </>
                  )}
                  <p></p>
                  {sales_agent&& (
                    <>
                      <p className={classes.contactHeader}>Contact for Sales:</p>
                      <p className={classes.contactPhone}>
                        {sales_agent}
                      </p>
                    </>
                  )}
                </div>
              </Grid>
            )
          }

          </Grid>

          {shouldDisplayTabs && (
            <Grid item xs>
              <Paper>
              <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                {visits.length >= 1 && (
                <Tab label="Visits" />
                )}
                {surveyResults.length >= 1 && (
                <Tab label="Survey" />
                )}
              </Tabs>
              {
                  visits.length >= 1
                  && tabIndex === TabIndex.FirstTab
                  && (
                    <CustomerVisits
                      visits={visits}
                      match={match}
                      location={location}
                    />
                  )
                }
                {
                  surveyResults.length >= 1
                  && tabIndex === TabIndex.SecondTab
                  && (
                    <SurveyTab surveyResults={surveyResults} />
                  )
                }
              </Paper>
            </Grid>
          )
          }
        </Grid>

      </Grid>

    </>
  );
};

export default React.memo(CustomerDetailsPage);
