export enum HubAccountType {
  EZLO_HUB = 'ezlo_hub',
  ADC_HUB = 'adc_hub',
  SMART_LOCK = 'smart_lock',
}

export const HUB_ACCOUNT_TYPE = [
  {
    value: HubAccountType.EZLO_HUB,
    label: 'Ezlo Hub',
  },
  {
    value: HubAccountType.ADC_HUB,
    label: 'ADC Hub',
  },
  {
    value: HubAccountType.SMART_LOCK,
    label: 'Smart Lock',
  },
];
