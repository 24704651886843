export class InputHubAccount {
  username: string = '';

  email: string = '';

  hashpw?: string = '';

  adc_group_id?: string = '';

  account_type?: string = '';

  smart_lock_connected_account_id?: string;

  builder_id?: number;
}

export class HubServerRelay {
  hubId: string = '';

  serverRelay: string = '';

  identity: string = '';

  identitySignature: string = '';

  controllerStatus: number = 0;
}
