import { gql } from 'apollo-boost';

export const ALL_HUB_ACCOUNTS = gql`
  query(
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $direction: String
  ) {
    hubAccounts(
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      direction: $direction
    ) {
      list {
        id
        username
        email
        hashpw
        adc_group_id
        kwikset_client_id
        created_on
        created_by
        modified_on
        modified_by
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const HUB_ACCOUNT_BY_ID = gql`
  query($id: String!) {
    getHubAccountById(id: $id) {
      id
      username
      email
      hashpw
      adc_group_id
      account_type
      smart_lock_connected_account_id
      builder_id
    }
  }
`;

export const HUB_SERVER_REPLAY_BY_ID = gql`
  query($id: String!) {
    getListEzloHubServerRelay(id: $id) {
      hubId
      serverRelay
      identity
      identitySignature
      controllerStatus
    }
  }
`;

export const UPDATE_HUB_ACCOUNT = gql`
  mutation updateHubAccount($hubAccount: inputHubAccount!) {
    updateHubAccount(hubAccount: $hubAccount) {
      id
    }
  }
`;

export const DELETE_HUB_ACCOUNTS = gql`
  mutation($ids: [String!]!) {
    deleteMultipleHubAccounts(ids: $ids)
  }
`;
